import React from "react"

import Bio from "../../components/Bio"
import Layout from "../../components/layout"
import SEO from "../../components/SEO"

import { Link, graphql, PageProps } from "gatsby"

type TagData = {
  tag: string
}

const Tags: React.FC<PageProps<GatsbyTypes.TagQuery, TagData>> = ({ data, pageContext, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"
    } tagged with "${tag}"`

  const siteTitle = data.site?.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={"posts tagged with " + pageContext?.tag} />
      <div>
        <h1>{tagHeader}</h1>
        <ol style={{ listStyle: `none` }}>
          {edges.map(({ node }) => {
            const post = node // わかりにくかったのでリネーム
            const title = post.frontmatter?.title || post.fields?.slug

            return (
              <li key={post.fields?.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={post.fields?.slug || ``} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small>{post.frontmatter?.date}</small>
                  </header>
                </article>
              </li>
            )
          })}
        </ol>
        <Link to="/tags">All tags</Link>
      </div>
      <hr/>
      <Bio />
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query Tag($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD hh:mm")
            description
            tags
          }
        }
      }
    }
  }
`